import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { PageProps } from 'gatsby';
import { Col, Container, Row } from 'react-bootstrap';
import Image from 'gatsby-image';
import { SiteMetadata } from '../types/site-metadata';
import GatsbyImageProps from 'gatsby-image';

type DataProps = {
  site: SiteMetadata;
  allImageSharp: {
    edges: [{
      node: GatsbyImageProps
    }]
  }
};

const OurVisionPage: React.FC<PageProps<DataProps>> = ({ data, location }) => {
  return (
    <Layout location={location} title={data.site.siteMetadata.title}>
      <SEO title="Our Vision" />
      <Container fluid={true}>
        <Row>
          <Col>
            <h3 className="text-uppercase">Empowering our Environmental Mindset with Knowledge</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            Ecosystem Cooperative is a project founded in 2020.<br />
            I have always been wanting to understand our economic and social activities that cause pollution,
            and climate change.  Over time, it became my desire to confront the challenges that hinder society
            from tackling the reality of environmental degradation and the overall lack of motivation, if not
            the lack of speed to invest in it.<br /><br />

            Eventually, this site is aimed at diversifying our knowledge, the way of thinking and living and
            conducting business activity— for the sake of our future, our wellness, sustainability of our
            society, nature and wildlife, and everything that comes in between which make our existence on
            Earth worthwhile.<br /><br />

            As a founder, Ecosystem Cooperative also marks the beginning of a truly personal journey and I hope
            you will enjoy this ride with me.<br /><br />

            Jane K.
          </Col>
          <Col>
            <Image
              fluid={data.allImageSharp.edges[0].node.fluid}
              alt={'our-vision.jpeg'}
              key={'our-vision.jpeg'}
            />
          </Col>
        </Row>
      </Container>
    </Layout >
  );
};

export default OurVisionPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allImageSharp(filter: {fluid: {originalName: {eq: "our-vision.jpeg"}}}) {
      edges {
        node {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
